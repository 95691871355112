import {
  darkGrey4,
  fieldRequiredColor,
  smallDesktopWidth,
  themeColor,
  white,
  aiLinearGradient,
  greyThemeDark1,
  black,
} from '@edulastic/colors'
import {
  CustomModalStyled,
  EduButton,
  FieldLabel,
  FlexContainer,
  TextInputStyled,
} from '@edulastic/common'
import { Form, Drawer, Modal } from 'antd'
import styled from 'styled-components'
import TitleWrapper from '../../../AssignmentCreate/common/TitleWrapper'

export const FormWrapper = styled(Form)`
  .ant-row {
    text-align: left;
    padding: 0px;
    label {
      font-weight: 600;
      font-size: 14px;
      line-height: 19px;
      text-transform: uppercase;
      color: #000000;
      &:after {
        display: none;
      }
    }
    .ant-form-item-children {
      .ant-input {
        min-height: 45px;
        margin-top: 10px;
      }
      .remote-autocomplete-dropdown {
        line-height: normal;
        .ant-input-affix-wrapper {
          .ant-input {
            min-height: 45px;
          }
        }
      }
      .ant-select-selection .ant-select-selection__rendered {
        min-height: 45px;
        .ant-select-selection-selected-value {
          margin-top: 7px;
        }
      }
      .ant-select-selection__choice {
        height: 35px !important;
      }
      .ant-select-arrow {
        top: 50% !important;
      }
    }
  }
`

export const StyledFilterLabel = styled(FieldLabel)`
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: bold;
  font-size: 11px;
  line-height: 14px;
  color: ${darkGrey4};
  margin-top: 1rem;
`

export const StyledRequired = styled.span`
  color: ${fieldRequiredColor};
  margin-left: 3px;
  font-size: 14px;
`

export const AiEduButton = styled(EduButton)`
  font-weight: ${({ fontWeight }) => fontWeight};
  margin-left: ${({ marginLeft }) => marginLeft || '0px'} !important;

  &.ant-btn.ant-btn-primary {
    border: none;
    background: ${({ aiStyle }) =>
      aiStyle ? `linear-gradient(225deg, ${aiLinearGradient})` : white};
    color: ${({ aiStyle }) => (aiStyle ? white : themeColor)};
    height: ${({ aiStyle }) => aiStyle && '28px'};
    width: '100%';
    margin: ${({ margin }) => margin && '0 5px'};
    border: 'none';
  }
  svg {
    margin: ${({ IconBtn }) => IconBtn && 0};
  }
  &:hover {
    &.ant-btn.ant-btn-primary {
      background: ${({ aiStyle }) =>
        aiStyle ? `linear-gradient(225deg, ${aiLinearGradient})` : white};
      color: ${({ aiStyle }) => (aiStyle ? white : themeColor)};
      border: none;
    }
  }
`

export const AIButton = styled.button`
  display: flex;
  justify-content: space-evenly;
  cursor: ${(props) => (props?.disabled ? 'not-allowed' : 'pointer')};
  opacity: ${(props) => (props?.disabled ? 0.3 : 'unset')};
  border-image: linear-gradient(225deg, ${aiLinearGradient}) 1;
  border-width: 1px;
  border-style: solid;
  padding: 5px;
  width: 205px;
  background-color: transparent;
  background: linear-gradient(225deg, ${aiLinearGradient});
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  font-family: Open Sans;
  font-size: 13px;
  font-weight: 600;
  line-height: 17.7px;
`

export const FullHeightAiEduButton = styled(AiEduButton)`
  &.ant-btn.ant-btn-primary {
    height: ${({ aiStyle }) => aiStyle && '36px'};
    margin: ${({ margin }) => margin && '0 5px !important'};

    @media (max-width: ${smallDesktopWidth}) {
      height: ${({ aiStyle }) => aiStyle && '30px'};
    }
  }
`

export const CreateAiTestTitleWrapper = styled(TitleWrapper)`
  padding: 0;
  margin: 0;
  line-height: 2;
  color: #fff;
  font-size: 1rem;
  text-align: left;
`

export const CreateAiTestWrapper = styled(FlexContainer)`
  border-radius: 10px;
  background: linear-gradient(225deg, ${aiLinearGradient});
`

export const StyledDiv = styled.div`
  width: calc(100% - 2.5rem);
  padding-left: 2rem;
`
export const StyledAiTestLoadingModal = styled(CustomModalStyled)`
  .ant-modal-content {
    .ant-modal-close {
      display: none;
    }
  }
`

export const CreateAiTestBannerWrapper = styled(FlexContainer)`
  border-radius: 12px;
  border: 1px solid #d8d8d8;
  height: 62px;
  background: white;
  width: max-content;
  gap: 1rem;
  padding: 1rem;
`

export const CreateAiTestBannerTitleWrapper = styled(TitleWrapper)`
  padding: 0;
  margin: 0;
  margin-left: -4px;
  font-size: 14px;
  text-align: left;
  heght: min-content;
  background: var(--Linear, linear-gradient(269deg, ${aiLinearGradient}));
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`
export const StyledFormFieldsContainer = styled.div``
export const StyledCreateAiTestDrawer = styled(Drawer)`
  .ant-drawer-content {
    .ant-drawer-body {
      padding-top: 0px;
      margin-bottom: 55px;
    }
  }
`

export const StyledQuestionContextInputInfo = styled.p`
  font-family: Open Sans;
  font-size: 12px;
  font-weight: 500;
  line-height: 16.34px;
  margin-bottom: 7px;
  text-align: left;
  color: #555555;
`

export const StyledDrawerFooter = styled.div`
  position: absolute;
  width: 100%;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: space-around;
  padding: 10px 16px;
  width: 100%;
  height: 55px;
  border: 1px 0px 0px 0px;
  box-shadow: 0px -2px 5px 0px rgba(0, 0, 0, 0.2);
  background-color: ${white};
`

export const StyledButtonText = styled.span`
  font-family: Open Sans;
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  text-align: left;
`

export const StyledDrawerTitle = styled.span`
  font-family: Open Sans;
  font-size: 16px;
  font-weight: 700;
  line-height: 21.79px;
  text-align: left;
  color: ${greyThemeDark1};
`

export const StyledAttachmentContainer = styled.div`
  display: flex;
  margin-top: 1rem;
  justify-content: space-between;
`

export const StyledAttachmentBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 238px;
  height: 48px;
  border-radius: 4px;
  border: 1px solid #bbbbbb;
  padding: 12px;
`

export const StyledAttachmentTextContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: Open Sans;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  text-align: left;
  color: #000000;
`

export const StyledAttachmentIconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: #2f4151;
  margin-right: 8px;
  cursor: ${(props) => props?.cursor || 'unset'};
`

export const StyledAttachmentLink = styled.button`
  font-family: Open Sans;
  font-size: 12px;
  font-weight: 700;
  line-height: 18px;
  text-align: left;
  color: ${(props) => (props?.disabled ? '#b3e8de' : '#11a084')};
  cursor: ${(props) => (props?.disabled ? 'not-allowed' : 'pointer')};
  border: none;
  background: none;
  padding: 0px;
`
export const StyledAttachmentDivider = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 15px;
  font-family: Open Sans;
  font-size: 12px;
  font-weight: 700;
  line-height: 18px;
  text-align: left;
  color: #555555;
`

export const StyledAttachmentInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
  gap: 10px;
`

export const StyledAttachmentHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const StyledURLInputContainer = styled.div`
  display: flex;
  height: 34px;
  border: ${(props) => `1px solid ${props?.isError ? '#E43334' : '#bbbbbb'}`};
  border-radius: 4px;
  align-items: center;
  justify-content: center;
  padding: 2px 5px 2px 8px;
  background-color: #f8f8f8;
`

export const StyledAttachmentHeaderText = styled.span`
  font-family: Open Sans;
  font-size: 12px;
  font-weight: 700;
  line-height: 16.34px;
  text-align: left;
  color: #434b5d;
`

export const StyledURLInput = styled(TextInputStyled)`
  &.ant-input {
    border: none;
    background-color: #f8f8f8;
    padding: 0px 5px 2px 10px;
    &:focus,
    &:hover {
      border: 0px !important;
      background-color: unset !important;
    }
  }
`

export const StyledAttachmentInfoText = styled.p`
  font-family: Open Sans;
  font-size: 11px;
  font-weight: 600;
  line-height: 14.98px;
  text-align: left;
  color: ${(props) => (props?.isError ? '#E43334' : '#555555')};
`
export const StyledClearUrlButton = styled.div`
  margin-top: 5px;
  cursor: pointer;
`
export const StyledAttachmentBetaTag = styled.div`
  padding: 1px 0px 2px 3px;
  height: 14px;
  width: 34px;
  font-family: Open Sans;
  font-size: 10px;
  font-weight: 700;
  line-height: 10px;
  text-align: left;
  left: -26px;
  border-radius: 2px;
  top: -55px;
  background-color: #fff;
  border: 1.5px solid rgba(63, 132, 229, 1);
  color: rgba(63, 132, 229, 1);
  position: relative;
  float: right;
  left: 7px;
`
export const StyledDocInputContainer = styled.div`
  height: 64px;
  border: 1px solid #bbbbbb;
  border-radius: 4px;
  background-color: #f8f8f8;
`

export const StyledHoverSpan = styled.span`
  cursor: pointer;
`
export const StyledILPDrawer = styled(Drawer)`
  .ant-drawer-content {
    .ant-drawer-body {
      padding: 0px;
    }
  }
`
export const StyledAITestCreationOptionsModal = styled(Modal)`
  .ant-modal-content {
    .ant-modal-close {
      svg {
        height: 20px;
        width: 20px;
        fill: #2f4151;
      }
    }
  }
  .ant-modal-body {
    padding: 0px 0px 15px 0px;
  }
`
export const StyledAITestCreationOptionsModalTitle = styled.p`
  font-family: Open Sans;
  font-size: 24px;
  font-weight: 400;
  line-height: 32px;
  text-align: left;
  color: ${black};
`
export const StyledAITestCreationOptionWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 100px;
  cursor: pointer;
  padding: 0px 25px;
  &:hover {
    background-color: #3f85e5;
    p {
      color: ${white};
    }
    .test-creation-option-icon-container {
      background-color: ${white};
    }
    .test-creation-option-beta-tag {
      color: #3f85e5;
      border: 1px solid ${white};
      background-color: ${white};
    }
  }
`
export const StyledAITestCreationOptionContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 15px;
`
export const StyledAITestCreationOptionIconContainer = styled.div`
  width: 32px;
  height: 32px;
  border-radius: 6px;
  border: 1px solid #d8d8d8;
  display: flex;
  align-items: center;
  justify-content: center;
`
export const StyledAITestCreationOption = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  max-width: 65%;
`
export const StyledAITestCreationOptionTitle = styled.p`
  font-family: Open Sans;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  color: #000000;
`
export const StyledAITestCreationOptionDescription = styled.p`
  font-family: Open Sans;
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  color: #555555;
`
export const StyledILPPackageContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: auto;
`
export const StyledILPIconsContainer = styled.div`
  display: flex;
  justify-content: space-between;
`
export const StyledILPIconsText = styled.p`
  font-family: Open Sans;
  font-weight: 500;
  font-size: 10px;
  line-height: 18px;
  color: #555555;
`
export const StyledAITestCreationOptionsDivider = styled.div`
  width: 90%;
  height: 0px;
  border-bottom: 1px solid #d8d8d8;
  margin: auto;
`
export const StyledAITestCreationOptionBetaTag = styled.span`
  font-family: Open Sans;
  font-weight: 700;
  font-size: 11px;
  line-height: 16px;
  color: #3f85e5;
  border: 1px solid #3f85e5;
  margin-left: 5px;
  border-radius: 3px;
  padding: 0px 4px 0px 4px;
  position: relative;
  top: -1px;
  left: 3px;
`
