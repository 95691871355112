import React from 'react'
import {
  IconPearDeck,
  IconAssignPearPractice,
  IconPearAssessmentTest,
} from '@edulastic/icons'
import {
  StyledAITestCreationOption,
  StyledAITestCreationOptionBetaTag,
  StyledAITestCreationOptionContainer,
  StyledAITestCreationOptionDescription,
  StyledAITestCreationOptionIconContainer,
  StyledAITestCreationOptionTitle,
  StyledAITestCreationOptionWrapper,
  StyledILPIconsContainer,
  StyledILPIconsText,
  StyledILPPackageContainer,
} from './styled'

const AITestCreationOption = ({
  dataCy,
  option,
  optionIcon,
  title,
  description,
  showPackageIcons,
  packageIconsText,
  showBetaTag,
  handleOptionSelect,
}) => {
  return (
    <StyledAITestCreationOptionWrapper
      onClick={() => handleOptionSelect(option)}
    >
      <StyledAITestCreationOptionContainer data-cy={dataCy}>
        <StyledAITestCreationOptionIconContainer className="test-creation-option-icon-container">
          {optionIcon}
        </StyledAITestCreationOptionIconContainer>
        <StyledAITestCreationOption>
          <StyledAITestCreationOptionTitle>
            {title}{' '}
            {showBetaTag && (
              <StyledAITestCreationOptionBetaTag className="test-creation-option-beta-tag">
                BETA
              </StyledAITestCreationOptionBetaTag>
            )}
          </StyledAITestCreationOptionTitle>
          <StyledAITestCreationOptionDescription>
            {description}
          </StyledAITestCreationOptionDescription>
        </StyledAITestCreationOption>
        {showPackageIcons && (
          <StyledILPPackageContainer>
            <StyledILPIconsContainer>
              <IconPearDeck />
              <IconAssignPearPractice />
              <IconPearAssessmentTest />
            </StyledILPIconsContainer>
            <StyledILPIconsText>{packageIconsText}</StyledILPIconsText>
          </StyledILPPackageContainer>
        )}
      </StyledAITestCreationOptionContainer>
    </StyledAITestCreationOptionWrapper>
  )
}

export default AITestCreationOption
