import React, { useEffect, useRef } from 'react'
import {
  getPDLDefaultGrade,
  getPDLDefaultSubject,
  getPDLDefaultSubjectId,
  getPDLGradeId,
} from './ducks/helpers'
import { StyledILPDrawer } from './styled'

const ILPDrawer = ({
  visible,
  setIsILPVisible,
  showILPProductSelectionScreen,
  defaultGrades = [],
  defaultSubjects = [],
}) => {
  const ILPDrawerRef = useRef(null)

  const getDefaultValuesAndLoadContentDrawer = async () => {
    const pdlDefaultGrade = getPDLDefaultGrade(defaultGrades)
    const pdlDefaultGradeId = await getPDLGradeId(pdlDefaultGrade)
    const pdlDefaultSubject = getPDLDefaultSubject(defaultSubjects)
    const pdlDefaultSubjectId = await getPDLDefaultSubjectId(pdlDefaultSubject)

    if (!ILPDrawerRef.current) return
    window.pear.generate.showGenerateContentDrawer({
      target: ILPDrawerRef.current,
      onClickClose: () => setIsILPVisible(false),
      contentTypes: showILPProductSelectionScreen
        ? undefined
        : ['TYPE_PEAR_ASSESSMENT_TEST'],
      expandAdvancedOptions: true,
      ...(pdlDefaultGradeId ? { gradeLevelId: pdlDefaultGradeId } : {}),
      ...(pdlDefaultSubjectId ? { subjectId: pdlDefaultSubjectId } : {}),
    })
  }

  useEffect(() => {
    getDefaultValuesAndLoadContentDrawer()
    return () => {
      ILPDrawerRef.current?.replaceChildren()
    }
  }, [])

  return (
    <StyledILPDrawer
      title={null}
      placement="right"
      width="650px"
      closable={false}
      onClose={() => setIsILPVisible(false)}
      visible={visible}
      destroyOnClose
    >
      <div ref={ILPDrawerRef} style={{ width: '100%', height: '100vh' }} />
    </StyledILPDrawer>
  )
}

export default ILPDrawer
