import React from 'react'
import { IconWarnCircle } from '@edulastic/icons'
import {
  StyledPDLMissingStandardsBanner,
  StyledPDLMissingStandardsBannerText,
  StyledPDLMissingStandardsBannerTextContainer,
} from './styled'

const PDLMissingStandardsBanner = () => {
  return (
    <StyledPDLMissingStandardsBanner>
      <IconWarnCircle color="#8E6601" />
      <StyledPDLMissingStandardsBannerTextContainer>
        <StyledPDLMissingStandardsBannerText>
          We couldn&apos;t find the selected standards in Pear Assessment.
          <p className="pdl-missing-standards-sub-text">
            This can sometimes happen, and is not your fault. You can add the
            standards manually to ensure proper tracking and reporting.
          </p>
        </StyledPDLMissingStandardsBannerText>
      </StyledPDLMissingStandardsBannerTextContainer>
    </StyledPDLMissingStandardsBanner>
  )
}

export default PDLMissingStandardsBanner
