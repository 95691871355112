import {
  MULTIPLE_CHOICE,
  MULTIPLE_SELECTION,
  TRUE_OR_FALSE,
} from '@edulastic/constants/const/questionType'
import {
  GRADE_KEYS,
  SUBJECTS_VALUES,
} from '@edulastic/constants/reportUtils/common'
import { cloneDeep, pick } from 'lodash'
import { processMcqStandardQuestion } from './aiQuestionToEduQuestion/processMcqStandard'
import { processMcqTrueAndFalse } from './aiQuestionToEduQuestion/processMcqTrueAndFalse'
import { processMSQ } from './aiQuestionToEduQuestion/processMsq'

export const processQuestionAndCreateItem = {
  [TRUE_OR_FALSE]: processMcqTrueAndFalse,
  [MULTIPLE_CHOICE]: processMcqStandardQuestion,
  [MULTIPLE_SELECTION]: processMSQ,
}

export const processAiGeneratedItems = ({
  questions,
  questionType,
  alignmentData,
  grades,
  subjects,
  existingQidToRegenerate,
  aiDocExtracted = false,
}) => {
  const testItems = []

  questions.forEach((question) => {
    const itemProcessor = processQuestionAndCreateItem[questionType]
    const item = itemProcessor(
      question,
      alignmentData,
      grades,
      subjects,
      aiDocExtracted
    )
    if (existingQidToRegenerate) {
      testItems.push({ ...item, _id: existingQidToRegenerate })
    } else {
      testItems.push(item)
    }
  })

  return testItems
}

export const getAlignmentDataForAiQuestions = ([alignment]) => {
  const {
    grades,
    subject,
    standards = [],
    curriculum: standardSet = '',
  } = alignment
  const standardIds = (standards || []).map(({ identifier }) => identifier)
  const standardDescriptions = (standards || []).map(
    ({ identifier = '', description = '' }) => ({
      id: identifier,
      description,
    })
  )
  return { grades, subject, standardIds, standardSet, standardDescriptions }
}
export const getAlignmentForEduItems = ([alignment], standardName) => {
  const { domains = [], standards = [], ...rest } = cloneDeep(alignment)
  const _domains = domains
    .filter(({ standards: sts }) =>
      sts.some(({ name }) => name === standardName)
    )
    .map((domain) => ({
      ...domain,
      standards: domain.standards.filter(({ name }) => name === standardName),
    }))

  const _standards = standards.filter(
    ({ identifier }) => identifier === standardName
  )

  return [
    {
      ...rest,
      domains: _domains,
      standards: _standards,
    },
  ]
}

export const formatStandard = (standard = {}) => {
  const formattedStandard = pick(standard, [
    '_id',
    'level',
    'grades',
    'identifier',
    'tloIdentifier',
    'tloId',
    'tloDescription',
    'eloId',
    'subEloId',
    'description',
    'curriculumId',
  ])
  return formattedStandard
}

export const getExistingQuestionContents = (assessment) => {
  const existingQuestionContent = []
  ;(assessment.itemGroups || []).forEach((itemGroup = []) => {
    const content = (itemGroup.items || [])
      .flatMap(({ data }) =>
        data?.questions.map(({ stimulus, questionDisplayTimestamp }) => ({
          name: stimulus,
          ...(questionDisplayTimestamp
            ? { displayAtSecond: questionDisplayTimestamp }
            : {}),
        }))
      )
      .filter(({ name }) => name)
    existingQuestionContent.push(...content)
  })
  return existingQuestionContent
}

const getPDLGradeMappedToPAGrade = (PAGrade = '') => {
  // need to return null because PDL doesn't have 'Other' grade option
  if (!PAGrade) {
    return null
  }
  switch (PAGrade) {
    case GRADE_KEYS.PRE_KG:
      return 'PK'
    case GRADE_KEYS.KG:
      return 'K'
    case GRADE_KEYS.GRADE_1:
      return '1'
    case GRADE_KEYS.GRADE_2:
      return '2'
    case GRADE_KEYS.GRADE_3:
      return '3'
    case GRADE_KEYS.GRADE_4:
      return '4'
    case GRADE_KEYS.GRADE_5:
      return '5'
    case GRADE_KEYS.GRADE_6:
      return '6'
    case GRADE_KEYS.GRADE_7:
      return '7'
    case GRADE_KEYS.GRADE_8:
      return '8'
    case GRADE_KEYS.GRADE_9:
      return '9'
    case GRADE_KEYS.GRADE_10:
      return '10'
    case GRADE_KEYS.GRADE_11:
      return '11'
    case GRADE_KEYS.GRADE_12:
      return '12'
    case GRADE_KEYS.OTHER:
    default:
      return null
  }
}

const getPDLSubjectMappedToPASubject = (PASubject = '') => {
  // need to return null because PDL doesn't have 'Other' subject option
  if (!PASubject) {
    return null
  }
  switch (PASubject) {
    case SUBJECTS_VALUES.MATHEMATICS:
      return 'MATH'
    case SUBJECTS_VALUES.SCIENCE:
      return 'SCI'
    case SUBJECTS_VALUES.SOCIAL_STUDIES:
      return 'SOC'
    case SUBJECTS_VALUES.COMPUTER_SCIENCE:
      return 'SCIT'
    case SUBJECTS_VALUES.ELA:
      return 'LANG'
    case SUBJECTS_VALUES.OTHER:
    default:
      return null
  }
}

export const getPDLDefaultGrade = (PAUserDefaultGrades = []) => {
  if (!PAUserDefaultGrades?.length) {
    return null
  }
  return getPDLGradeMappedToPAGrade(PAUserDefaultGrades[0] || '')
}

export const getPDLGradeId = async (PDLGrade = '') => {
  if (PDLGrade && window?.pear?.standards?.listGradeLevels) {
    const PDLGrades = await window.pear.standards.listGradeLevels({
      perPage: 100,
    })
    if (PDLGrades?.length) {
      return PDLGrades.find(({ code = '' } = {}) => code === PDLGrade)?.id
    }
  }
  return null
}

export const getPDLDefaultSubject = (PAUserDefaultSubjects = []) => {
  if (!PAUserDefaultSubjects?.length) {
    return null
  }
  return getPDLSubjectMappedToPASubject(PAUserDefaultSubjects[0] || '')
}

export const getPDLDefaultSubjectId = async (PDLSubject = '') => {
  if (PDLSubject && window?.pear?.standards?.listSubjects) {
    const PDLSubjects = await window.pear.standards.listSubjects({
      perPage: 100,
    })
    if (PDLSubjects?.length) {
      return PDLSubjects.find(({ code = '' } = {}) => code === PDLSubject)?.id
    }
  }
  return null
}
