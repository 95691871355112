import {
  EduElse,
  EduIf,
  EduThen,
  FieldLabel,
  FlexContainer,
  SelectInputStyled,
  TextInputStyled,
} from '@edulastic/common'
import { greyThemeDark5, red, accessibilityColors } from '@edulastic/colors'
import { Select, Spin, Tooltip } from 'antd'
import { TextAreaInputStyled } from '@edulastic/common/src/components/InputStyles'
import PropTypes from 'prop-types'
import React, { createRef, useEffect, useMemo, useState } from 'react'
import { withRouter } from 'react-router'
import { selectsData } from '../../../common'
import SummaryHeader from '../SummaryHeader/SummaryHeader'
import { AnalyticsItem, Block, ErrorWrapper, MetaTitle } from './styled'
import { isValidVideoUrl } from '../../../../../AssessmentPage/VideoQuiz/utils/videoPreviewHelpers'
import useTagSelection from '../../../common/useTagSelection'
import { setSelectSearchAriaLabel } from '../../../../../../common/utils/helpers'

export const renderAnalytics = (title, Icon, isLiked = false, cyAttrIndex) => (
  <AnalyticsItem>
    <Icon
      color={isLiked ? red : accessibilityColors.testLibrary.footerIconText}
      width={15}
      height={15}
    />
    <MetaTitle data-cy={`${cyAttrIndex}`}>{title}</MetaTitle>
  </AnalyticsItem>
)

const Sidebar = ({
  title,
  subjects,
  onChangeSubjects,
  onChangeField,
  tags = [],
  owner,
  analytics,
  grades,
  onChangeGrade,
  collections = [],
  collectionsToShow = [],
  onChangeCollection,
  description,
  createdBy,
  thumbnail,
  addNewTag,
  allTagsData,
  windowWidth,
  isEditable,
  test,
  toggleTestLikeRequest,
  videoUrl,
  isTestLoading,
  highlightError,
  history,
}) => {
  const subjectsList = selectsData.allSubjects
  const testTitleInput = createRef()
  const [isVideoUrlTouched, setIsVideoUrlTouched] = useState(false)
  const {
    searchValue,
    selectTags,
    searchTags,
    deselectTags,
    newAllTagsData,
    selectedTags,
  } = useTagSelection({
    tags,
    onChangeField,
    addNewTag,
    allTagsData,
  })

  useEffect(() => {
    if (testTitleInput.current) {
      testTitleInput.current.input.focus()
    }
  }, [])

  const filteredCollections = useMemo(
    () =>
      collections.filter((c) => collectionsToShow.some((o) => o._id === c._id)),
    [collections, collectionsToShow]
  )

  const { derivedFromPremiumBankId = false } = test

  useEffect(() => {
    if (test.videoUrl === 'https://www.youtube.com/watch?v=') {
      onChangeField('videoUrl', '')
    }
  }, [test.videoUrl])

  const videoUrlInput = (disabled = false) => (
    <TextInputStyled
      showArrow
      value={videoUrl}
      data-cy="videoUrl"
      onChange={(e) => {
        if (!isVideoUrlTouched) {
          setIsVideoUrlTouched(true)
        }
        onChangeField('videoUrl', e.target.value)
      }}
      size="large"
      placeholder="Enter the video URL"
      margin="0px 0px 15px"
      disabled={disabled}
      color={disabled && greyThemeDark5}
    />
  )

  const showFormError = highlightError || history.location.state?.highlightError
  const missingGrade = showFormError && (grades || []).length === 0
  const missingSubject = showFormError && (subjects || []).length === 0

  return (
    <FlexContainer padding="30px" flexDirection="column">
      <Spin spinning={isTestLoading}>
        <Block>
          <SummaryHeader
            createdBy={createdBy}
            thumbnail={thumbnail}
            owner={owner}
            windowWidth={windowWidth}
            analytics={analytics}
            onChangeField={onChangeField}
            isEditable={isEditable}
            test={test}
            toggleTestLikeRequest={toggleTestLikeRequest}
          />
          <FieldLabel>Test Name</FieldLabel>
          <TextInputStyled
            showArrow
            value={title}
            data-cy="testname"
            onChange={(e) => onChangeField('title', e.target.value)}
            size="large"
            placeholder="Enter the test name"
            ref={testTitleInput}
            margin="0px 0px 15px"
            aria-label="Test Name"
          />
          {title !== undefined && !title.trim().length ? (
            <ErrorWrapper>Please enter test title.</ErrorWrapper>
          ) : null}
          <EduIf condition={videoUrl !== undefined}>
            <>
              <FieldLabel>Video URL</FieldLabel>
              <EduIf condition={test?.isUsed}>
                <EduThen>
                  <Tooltip
                    placement="rightTop"
                    title="Video URL can’t be edited for assigned test"
                  >
                    <div>{videoUrlInput(!!test?.isUsed)}</div>
                  </Tooltip>
                </EduThen>
                <EduElse>{videoUrlInput()}</EduElse>
              </EduIf>
              <EduIf condition={isVideoUrlTouched}>
                <EduIf
                  condition={videoUrl !== undefined && !videoUrl.trim().length}
                >
                  <EduThen>
                    <ErrorWrapper>Please enter video URL.</ErrorWrapper>
                  </EduThen>
                  <EduElse>
                    <EduIf condition={videoUrl && !isValidVideoUrl(videoUrl)}>
                      <ErrorWrapper>{`This link can't be played.`}</ErrorWrapper>
                    </EduIf>
                  </EduElse>
                </EduIf>
              </EduIf>
            </>
          </EduIf>
          <FieldLabel>Description</FieldLabel>
          <TextAreaInputStyled
            value={description}
            onChange={(e) => onChangeField('description', e.target.value)}
            size="large"
            placeholder="Enter a description"
            margin="0px 0px 15px"
            height="110px"
            aria-label="Description"
          />
          <FieldLabel>Grade</FieldLabel>
          <SelectInputStyled
            isError={missingGrade}
            showArrow
            data-cy="gradeSelect"
            mode="multiple"
            size="large"
            placeholder="Please select"
            defaultValue={grades}
            onChange={onChangeGrade}
            optionFilterProp="children"
            getPopupContainer={(trigger) => trigger.parentNode}
            margin="0px 0px 15px"
            filterOption={(input, option) =>
              option.props.children
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0
            }
            ref={(ref) => {
              setSelectSearchAriaLabel(ref, 'Search Grades')
            }}
          >
            {selectsData.allGrades.map(({ value, text }) => (
              <Select.Option key={value} value={value}>
                {text}
              </Select.Option>
            ))}
          </SelectInputStyled>
          {missingGrade && <ErrorWrapper>Please select grades.</ErrorWrapper>}
          <FieldLabel>Subject</FieldLabel>
          <SelectInputStyled
            isError={missingSubject}
            showArrow
            data-cy="subjectSelect"
            mode="multiple"
            size="large"
            margin="0px 0px 15px"
            placeholder="Please select"
            defaultValue={subjects}
            onChange={onChangeSubjects}
            optionFilterProp="children"
            getPopupContainer={(trigger) => trigger.parentNode}
            filterOption={(input, option) =>
              option.props.children
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0
            }
            ref={(ref) => {
              setSelectSearchAriaLabel(ref, 'Search Subjects')
            }}
          >
            {subjectsList.map(({ value, text }) => (
              <Select.Option key={value} value={value}>
                {text}
              </Select.Option>
            ))}
          </SelectInputStyled>
          {missingSubject && (
            <ErrorWrapper>Please select subject.</ErrorWrapper>
          )}
          {collectionsToShow.length > 0 && (
            <>
              <FieldLabel>Collections</FieldLabel>
              <Tooltip
                title={
                  derivedFromPremiumBankId &&
                  'Action not permitted on clone of a premium content'
                }
              >
                <SelectInputStyled
                  showArrow
                  data-cy="collectionsSelect"
                  mode="multiple"
                  size="large"
                  margin="0px 0px 15px"
                  placeholder="Please select"
                  disabled={derivedFromPremiumBankId}
                  value={filteredCollections.flatMap((c) => c.bucketIds)}
                  onChange={(value, options) =>
                    onChangeCollection(value, options)
                  }
                  optionFilterProp="children"
                  getPopupContainer={(trigger) => trigger.parentNode}
                  filterOption={(input, option) =>
                    option.props.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {collectionsToShow.map((o) => (
                    <Select.Option
                      key={o.bucketId}
                      value={o.bucketId}
                      _id={o._id}
                      type={o.type}
                      collectionName={o.collectionName}
                    >
                      {`${o.collectionName} - ${o.name}`}
                    </Select.Option>
                  ))}
                </SelectInputStyled>
              </Tooltip>
            </>
          )}
          <FieldLabel>Tags</FieldLabel>
          <SelectInputStyled
            showArrow
            data-cy="tagsSelect"
            className="tagsSelect"
            mode="multiple"
            size="large"
            margin="0px 0px 15px"
            optionLabelProp="title"
            placeholder="Please enter"
            value={selectedTags}
            onSearch={searchTags}
            onSelect={selectTags}
            onDeselect={deselectTags}
            getPopupContainer={(trigger) => trigger.parentNode}
            filterOption={(input, option) =>
              option.props.title
                .toLowerCase()
                .includes(input.trim().toLowerCase())
            }
            ref={(ref) => {
              setSelectSearchAriaLabel(ref, 'Search Tags')
            }}
          >
            {searchValue?.trim() ? (
              <Select.Option key={0} value={searchValue} title={searchValue}>
                {`${searchValue} (Create new Tag)`}
              </Select.Option>
            ) : (
              ''
            )}
            {newAllTagsData.map(({ tagName, _id }) => (
              <Select.Option key={_id} value={_id} title={tagName}>
                {tagName}
              </Select.Option>
            ))}
          </SelectInputStyled>
          {!!searchValue.length && !searchValue.trim().length && (
            <p style={{ color: 'red' }}>Please enter valid characters.</p>
          )}
        </Block>
      </Spin>
    </FlexContainer>
  )
}

Sidebar.propTypes = {
  title: PropTypes.string.isRequired,
  tags: PropTypes.array.isRequired,
  onChangeField: PropTypes.func.isRequired,
  analytics: PropTypes.array.isRequired,
  grades: PropTypes.array.isRequired,
  onChangeGrade: PropTypes.func.isRequired,
  windowWidth: PropTypes.number.isRequired,
  subjects: PropTypes.array.isRequired,
  owner: PropTypes.bool,
  description: PropTypes.string.isRequired,
  createdBy: PropTypes.object,
  thumbnail: PropTypes.string,
  onChangeSubjects: PropTypes.func.isRequired,
}

Sidebar.defaultProps = {
  owner: false,
  createdBy: '',
  thumbnail: '',
}

export default withRouter(Sidebar)
