import { groupBy, omit, get, isEmpty } from 'lodash'
import memoizeOne from 'memoize-one'
import { test as testConstants } from '@edulastic/constants'

const { statusConstants } = testConstants

export const groupTestItemsByPassageId = (items = []) => {
  const groupByPassageId = groupBy(items, 'passageId')
  const _items = items
    .map((item) => {
      if (!item.passageId) {
        return item
      }
      const grouped = groupByPassageId[item.passageId]
      delete groupByPassageId[item.passageId]

      if (grouped && grouped.length <= 1) {
        return item
      }

      return grouped
    })
    .filter((x) => !!x)

  return _items
}

/**
 * @param {*} itemGroups : complete itemGroups from test
 * First attach the groupId to all the items, this is to later identify the group in which it belongs,
 * specifically for section re order function
 * The flattened items will be again looping through to group the items by passageId:
 * The purpose of grouping is for moving them together when reordering
 * When the passages are grouped in this flattened list it is easy to move them as a single index
 * UI expects all the passage item to have same index
 * @returns [{_id: 'itemId1', .. item},{hasPassage: true, passageId: "passageId1", groupId: "groupId1", selected: true, items: Array(2)}]
 */
export const getItemsGroupedByPassageId = (itemGroups) => {
  // create a flattened array of items with groupId
  const testItems = itemGroups.flatMap(({ items = [], _id }) =>
    items.map((item) => ({ ...item, groupId: _id }))
  )
  const groupByPassageId = groupBy(testItems, 'passageId')
  const _items = testItems
    .map((item) => {
      if (!item || !item.passageId) {
        return item
      }
      if (!groupByPassageId[item.passageId]) {
        return null
      }
      const grouped = groupByPassageId[item.passageId]
      delete groupByPassageId[item.passageId]

      if (grouped && grouped.length <= 1) {
        return item
      }
      // create a new object for passages with same passageId all together
      return {
        hasPassage: true,
        passageId: item.passageId,
        groupId: item.groupId,
        selected: grouped.some((passageItem) => passageItem.selected),
        items: grouped,
      }
    })
    .filter((x) => !!x)

  return _items
}

export const getAdaptiveTestSectionTotalPoints = ({
  itemGroupItems = [],
  testItemsUpdatedScores = {},
}) => {
  if (!itemGroupItems?.length) {
    return 0
  }
  return (itemGroupItems || []).reduce((acc, currentItem) => {
    const { _id: itemId = '', maxScore = 0 } = currentItem || {}
    const updatedScore = get(testItemsUpdatedScores, `${itemId}`)
    const isItemScoreUpdated = typeof updatedScore === 'number'
    const itemScore = isItemScoreUpdated ? updatedScore : maxScore
    return acc + itemScore
  }, 0)
}

export const isAdaptiveSectionMismatch = (
  adaptiveDelivery,
  itemGroups,
  isSectionAdaptiveTest,
  testItemsUpdatedScores
) => {
  const [baseSection, easySection, hardSection] = itemGroups
  const baseSectionItems = baseSection?.items || []
  const easySectionItems = easySection?.items || []
  const hardSectionItems = hardSection?.items || []
  const mismatch = {
    0: {
      points: false,
      itemRequired: false,
      itemsCount: false,
    },
    1: {
      points: false,
      itemRequired: false,
      itemsCount: false,
    },
    2: {
      points: false,
      itemRequired: false,
      itemsCount: false,
    },
  }
  if (!isSectionAdaptiveTest) {
    return mismatch
  }

  let shouldCheckSectionsPointMismatch = true

  if (
    easySectionItems.length !==
    adaptiveDelivery?.threshold?.thresholdSectionMaxItemsCount
  ) {
    mismatch[1].itemsCount = true
    mismatch[1].itemRequired = true
    shouldCheckSectionsPointMismatch = false
  }
  if (
    hardSectionItems.length !==
    adaptiveDelivery?.threshold?.thresholdSectionMaxItemsCount
  ) {
    mismatch[2].itemsCount = true
    mismatch[2].itemRequired = true
    shouldCheckSectionsPointMismatch = false
  }

  if (shouldCheckSectionsPointMismatch) {
    const easyPoints = getAdaptiveTestSectionTotalPoints({
      itemGroupItems: easySectionItems,
      testItemsUpdatedScores,
    })
    const hardPoints = getAdaptiveTestSectionTotalPoints({
      itemGroupItems: hardSectionItems,
      testItemsUpdatedScores,
    })
    if (easyPoints !== hardPoints) {
      mismatch[1].points = true
      mismatch[2].points = true
    }
  }

  if (baseSectionItems.length !== adaptiveDelivery?.baseSectionMaxItemsCount) {
    mismatch[0].itemRequired = true
  }
  return mismatch
}

export const isInvalidSectionAdaptiveTestData = ({
  adaptiveDelivery,
  itemGroups,
}) => {
  const {
    baseSectionMaxItemsCount,
    threshold: { value: thresholdValue, thresholdSectionMaxItemsCount } = {},
  } = adaptiveDelivery || {}

  if (typeof thresholdValue !== 'number') {
    return [true, 'Threshold value cannot be empty']
  }
  if (typeof baseSectionMaxItemsCount !== 'number') {
    return [true, 'Section 1 items required value cannot be empty']
  }
  if (typeof thresholdSectionMaxItemsCount !== 'number') {
    return [true, 'Section 2 Easy/Hard items required value cannot be empty']
  }

  let isInvalid = [false, '']
  const emptyScaledScoreGroupsNames = []
  const zeroScaledScoreGroupsNames = []
  const invalidScaledScoreGroupsNames = []
  itemGroups.forEach((itemGroup) => {
    const {
      settings: { scaledScore: { min, max } = {} } = {},
      groupName,
    } = itemGroup
    if (typeof min !== 'number' || typeof max !== 'number') {
      emptyScaledScoreGroupsNames.push(groupName)
    } else if (min === 0 || max === 0) {
      zeroScaledScoreGroupsNames.push(groupName)
    } else if (min > max) {
      invalidScaledScoreGroupsNames.push(groupName)
    }
  })
  if (emptyScaledScoreGroupsNames.length) {
    isInvalid = [
      true,
      `${emptyScaledScoreGroupsNames.join(', ')} scaled score cannot be empty`,
    ]
  } else if (zeroScaledScoreGroupsNames.length) {
    isInvalid = [
      true,
      `${zeroScaledScoreGroupsNames.join(', ')} scaled score cannot be zero`,
    ]
  } else if (invalidScaledScoreGroupsNames.length) {
    isInvalid = [
      true,
      `${invalidScaledScoreGroupsNames.join(
        ', '
      )} scaled score min value cannot be more than max value`,
    ]
  }

  return isInvalid
}

export const getIsInvalidSectionsAdaptiveTestDataAndMessage = ({
  adaptiveDelivery,
  itemGroups,
  testItemsUpdatedScores,
}) => {
  const [isInvalid, message] = isInvalidSectionAdaptiveTestData({
    adaptiveDelivery,
    itemGroups,
  })
  if (isInvalid) {
    return [isInvalid, message]
  }
  let isInvalidData = [false, '']
  const mismatch = isAdaptiveSectionMismatch(
    adaptiveDelivery,
    itemGroups,
    true,
    testItemsUpdatedScores
  )
  Object.keys(mismatch).forEach((itemGroupIndex) => {
    const { points, itemRequired, itemsCount } = mismatch[itemGroupIndex]
    if (points) {
      isInvalidData = [
        true,
        'Section 2 Easy and Hard need to have same points.',
      ]
    }
    if (itemRequired) {
      isInvalidData = [
        true,
        'Number of Items added need to be equal to Items required for all sections.',
      ]
    }
    if (itemsCount) {
      isInvalidData = [
        true,
        'Section 2 Easy and Hard need to have same Total Items.',
      ]
    }
  })
  return isInvalidData
}

// This is only for re ordering items between sections
// Same section re ordering is handled with old code
export const reOrderItemToNewIndex = (
  targetIndex,
  selectedIndex,
  itemGroups,
  groupedItems
) => {
  const selectedItem = groupedItems[selectedIndex]
  const targetLocationItem = groupedItems[targetIndex]
  groupedItems.splice(selectedIndex, 1)
  groupedItems.splice(targetIndex, 0, {
    ...selectedItem,
    groupId: targetLocationItem.groupId,
  })
  const groupMap = groupBy(groupedItems, 'groupId')
  const updatedItemGroups = itemGroups.map((itemGroup) => {
    const itemsGrouped = groupMap[itemGroup._id] || []
    const items = []
    itemsGrouped.forEach(({ groupId, selected, ...item }) => {
      if (item.hasPassage) {
        items.push(...item.items.map((x) => omit(x, ['selected', 'groupId'])))
      } else {
        items.push(item)
      }
    })
    return {
      ...itemGroup,
      items,
    }
  })

  return updatedItemGroups
}

const _showPDLMissingStandardsBanner = (testData, testItems) => {
  if (
    isEmpty(testItems) ||
    isEmpty(testData) ||
    testData?.externalSourceMetaData?.source !== 'PDL' ||
    !testData?.externalSourceMetaData?.isStandardsSelected
  ) {
    return false
  }
  const isStandardsMissingForAnyQuestion = testItems
    .flatMap((item) => item?.data?.questions || [])
    .some((question) => isEmpty(question.alignment))

  return (
    testData.externalSourceMetaData?.source === 'PDL' &&
    testData.externalSourceMetaData?.isStandardsSelected &&
    isStandardsMissingForAnyQuestion &&
    testData?.status !== statusConstants.PUBLISHED
  )
}

export const getShowPDLMissingStandardsBanner = memoizeOne(
  _showPDLMissingStandardsBanner
)
